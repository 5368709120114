import gglRound from './gglRound'

export const translateToPercentage = ({ key = 'carbo', valueCarbo = '0', valueFat = '0', valueProtein = '0' }) => {
  try {
    const calculatedCarbo = parseFloat(valueCarbo) * 4
    const calculatedProtein = parseFloat(valueProtein) * 4
    const calculatedFat = parseFloat(valueFat) * 9
    const resultAccumulated = calculatedFat + calculatedProtein + calculatedCarbo
    const resultCarbo = (calculatedCarbo / resultAccumulated) * 100
    const resultProtein = (calculatedProtein / resultAccumulated) * 100
    const resultFat = (calculatedFat / resultAccumulated) * 100

    if (key === 'carbo') {
      return `${isNaN(resultCarbo) ? '0' : resultCarbo.toFixed(0)}%`
    } else if (key === 'protein') {
      return `${isNaN(resultProtein) ? '0' : resultProtein.toFixed(0)}%`
    } else {
      return `${isNaN(resultFat) ? '0' : resultFat.toFixed(0)}%`
    }
  } catch (e) {
    return '0%'
  }
}

export const translateDataMealplanToTooltips = (dataMeals, type) => {
  let result = []
  if (type === 'carbohydrate') {
    // CARBOHYDRATE
    result = dataMeals.sort((a, b) => (parseFloat(b.carbohydrate) || 0) - (parseFloat(a.carbohydrate) || 0))
  } else if (type === 'fat') {
    // FAT
    result = dataMeals.sort((a, b) => (parseFloat(b.fat) || 0) - (parseFloat(a.fat) || 0))
  } else if (type === 'protein') {
    // PROTEIN
    result = dataMeals.sort((a, b) => (parseFloat(b.protein) || 0) - (parseFloat(a.protein) || 0))
  } else if (type === 'sugar') {
    // SUGAR
    result = dataMeals.sort((a, b) => (parseFloat(b.sugar) || 0) - (parseFloat(a.sugar) || 0))
  } else if (type === 'fiber') {
    // FIBER
    result = dataMeals.sort((a, b) => (parseFloat(b.fiber) || 0) - (parseFloat(a.fiber) || 0))
  } else if (type === 'calorie') {
    // CALORIES
    result = dataMeals.sort((a, b) => (parseFloat(b.calorie) || 0) - (parseFloat(a.calorie) || 0))
  }
  return gglRound(result)
}
