import { Box, Image, Stack, Text, Tooltip } from '@chakra-ui/react'
import React, { useState } from 'react'

const NutritionBadge = ({ status, blur, meals, nutritionType }) => {
  const [open, setOpen] = useState(false)
  const nutritionNames = {
    carbohydrate: 'Karbo',
    fat: 'Lemak',
    protein: 'Protein',
    sugar: 'Gula',
    fiber: 'Serat',
    calorie: 'Kalori',
  }
  if (status == 'kurang')
    return (
      <Tooltip
        p={3}
        mx={3}
        borderRadius={4}
        hasArrow
        isOpen={open}
        label={
          <Box>
            <Text fontSize={14}>Makanan mengandung {nutritionNames[nutritionType]} tertinggi: </Text>
            {meals?.map((meal, index) => (
              <Text fontSize={14} key={meal.id}>
                {index + 1}. {meal.food.data.name}{' '}
                {nutritionType === 'calorie' ? `(${meal[nutritionType]}) Kalori` : `${meal[nutritionType]} gr`}
              </Text>
            ))}
          </Box>
        }
        bg="white"
        color="black"
      >
        <Box
          position="relative"
          onClick={() => setOpen(!open)}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <Box
            bg="state.warning2"
            color="state.warning"
            p="2px 6px"
            fontSize="12px"
            fontWeight={600}
            w="fit-content"
            borderRadius="2px"
            filter={blur ? 'blur(2px)' : ''}
          >
            kurang
          </Box>
          {blur && (
            <Image
              src="/icons/king.svg"
              left="18px"
              top="2.5px"
              position="absolute"
              alt=""
              filter="drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.35))"
            />
          )}
        </Box>
      </Tooltip>
    )
  else if (status === 'cukup')
    return (
      <Tooltip
        p={3}
        mx={3}
        borderRadius={4}
        hasArrow
        isOpen={open}
        label={
          <Box>
            <Text fontSize={14}>Makanan mengandung {nutritionNames[nutritionType]} tertinggi: </Text>
            {meals?.map((meal, index) => (
              <Text fontSize={14} key={meal.id}>
                {index + 1}. {meal.food.data.name}{' '}
                {nutritionType === 'calorie' ? `(${meal[nutritionType]}) Kalori` : `${meal[nutritionType]} gr`}
              </Text>
            ))}
          </Box>
        }
        bg="white"
        color="black"
      >
        <Box
          position="relative"
          onClick={() => setOpen(!open)}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <Box
            bg="state.info2"
            color="state.info"
            p="2px 6px"
            fontSize="12px"
            fontWeight={600}
            w="fit-content"
            borderRadius="2px"
            filter={blur ? 'blur(2px)' : ''}
          >
            cukup
          </Box>
          {blur && (
            <Image
              src="/icons/king.svg"
              left="16px"
              top="2.5px"
              position="absolute"
              alt=""
              filter="drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.35))"
            />
          )}
        </Box>
      </Tooltip>
    )
  else if (status === 'berlebih')
    return (
      <Tooltip
        p={3}
        mx={3}
        borderRadius={4}
        hasArrow
        isOpen={open}
        label={
          <Box>
            <Text fontSize={14}>Makanan mengandung {nutritionNames[nutritionType]} tertinggi: </Text>
            {meals?.map((meal, index) => (
              <Text fontSize={14} key={meal.id}>
                {index + 1}. {meal.food.data.name}{' '}
                {nutritionType === 'calorie' ? `(${meal[nutritionType]}) Kalori` : `${meal[nutritionType]} gr`}
              </Text>
            ))}
          </Box>
        }
        bg="white"
        color="black"
      >
        <Box
          position="relative"
          onClick={() => setOpen(!open)}
          onMouseEnter={() => setOpen(true)}
          onMouseLeave={() => setOpen(false)}
        >
          <Box
            bg="state.danger2"
            color="state.danger"
            p="2px 6px"
            fontSize="12px"
            fontWeight={600}
            w="fit-content"
            borderRadius="2px"
            filter={blur ? 'blur(2px)' : ''}
          >
            berlebih
          </Box>
          {blur && (
            <Image
              src="/icons/king.svg"
              left="21px"
              top="2.5px"
              position="absolute"
              alt=""
              filter="drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.35))"
            />
          )}
        </Box>
      </Tooltip>
    )
}

export default NutritionBadge
