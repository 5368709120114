import {
  Box,
  Center,
  Circle,
  HStack,
  Image,
  Slider,
  SliderFilledTrack,
  SliderTrack,
  Stack,
  Text,
  Tooltip,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'

const GoalSlider = ({ name, currentValue, unit, min, max, meals }) => {
  const [openTooltips, setOpenTooltips] = useState(false)
  const statusColor = currentValue < min || currentValue >= max ? 'logo.primary' : 'button.default'
  const [sliderValue, setSliderValue] = useState(Math.min(100, (currentValue / max) * 85))

  useEffect(() => {
    setSliderValue(Math.min(100, (currentValue / max) * 85))
  }, [currentValue, min, max])

  const nutritionValues = {
    Karbohidrat: 'carbohydrate',
    Lemak: 'fat',
    Protein: 'protein',
    Gula: 'sugar',
    Serat: 'fiber',
    Kalori: 'calorie',
  }

  return (
    <Box>
      <HStack alignItems="center" justifyContent="space-between" mt={4}>
        <Text fontWeight="600" fontSize="16px" color="#002C3F">
          {name}
        </Text>
        <Text color="#4E4B66" fontSize="12px" fontWeight={600}>
          Tips
        </Text>
      </HStack>

      <HStack spacing={0} position="relative" mt={4}>
        <Slider
          value={Math.max(10, sliderValue + 5)}
          size="lg"
          isDisabled
          _disabled={{
            bg: statusColor,
          }}
          mr="24px"
          w="100%"
        >
          <SliderTrack h="32px" borderRadius="20px" bg="#FCEBEB">
            <SliderFilledTrack
              position="relative"
              bg={statusColor}
              zIndex={4}
              w={`${sliderValue}%`}
              h="32px"
              borderRadius="20px"
            >
              <Center zIndex={2} position="absolute" right={currentValue < 10 ? '10px' : '8px'} top="6px">
                <Text fontSize="14px" fontWeight={600} color="white">
                  {Math.floor(currentValue)}
                </Text>
              </Center>
            </SliderFilledTrack>
            <Box
              w={min ? `${(min / max) * 90}%` : '0%'}
              position="absolute"
              bg={'#FCEBEB'}
              h="32px"
              borderRadius="20px"
              zIndex={3}
            />
            <Box w="90%" position="absolute" bg={'#E5ECFB'} h="32px" borderRadius="20px" zIndex={2} />
            <Box w="100%" position="absolute" bg={'#FFD3D9'} h="32px" borderRadius="20px" zIndex={1} />
          </SliderTrack>
        </Slider>

        {min && (
          <HStack position="absolute" top="-30px" left="60%" zIndex={2}>
            <Circle w="12px" h="12px" bg="button.default" />
            <Text color="default.body" fontWeight={500}>
              {Math.round(min)} - {Math.round(max)} {unit}
            </Text>
          </HStack>
        )}
        {!min && (
          <HStack position="absolute" top="-40px" left="60%" zIndex={2}>
            <Circle w="12px" h="12px" bg="button.default" />
            <Text color="default.body" fontWeight={500}>
              Max {Math.round(max)} {unit}
            </Text>
          </HStack>
        )}

        {currentValue > max && (
          <Tooltip
            borderRadius={6}
            hasArrow
            isOpen={openTooltips}
            label={
              <Stack>
                <HStack>
                  <Image src="/icons/lightbulb.png" alt="" w="16px" />
                  <Text fontSize={14}>Kurangi {Math.round(currentValue - max)} gr agar Goal Biru</Text>
                </HStack>
                <Text fontSize={14}>{name} kamu sudah melebihi kebutuhan. kurangi porsi makanan berikut:</Text>
                <Box>
                  {meals?.map((meal, index) => (
                    <Text fontSize={14} key={index}>
                      {index + 1}. {meal.food.data.name} {`(${meal[nutritionValues[name]]}) ${name}`}
                    </Text>
                  ))}
                </Box>
              </Stack>
            }
            bg="white"
            color="black"
            mr={2}
            placement="bottom-end"
          >
            <Image
              src="/icons/mui-remove.svg"
              alt=""
              onMouseEnter={() => setOpenTooltips(true)}
              onMouseLeave={() => setOpenTooltips(false)}
            />
          </Tooltip>
        )}
        {currentValue < min && currentValue !== 0 && name !== 'Gula' && (
          <Tooltip
            borderRadius={6}
            hasArrow
            placement="bottom-end"
            isOpen={openTooltips}
            label={
              <Stack>
                <HStack>
                  <Image src="/icons/lightbulb.png" alt="" w="16px" />
                  <Text fontSize={14}>Tambahkan {Math.round(min - currentValue)} gr agar Goal Biru</Text>
                </HStack>
                <Text fontSize={14}>Kebutuhan {name} kamu belum terpenuhi. Tambah porsi makanan berikut:</Text>
                <Box>
                  {meals?.map((meal, index) => (
                    <Text fontSize={14} key={index}>
                      {index + 1}. {meal.food.data.name} {`(${meal[nutritionValues[name]]}) ${name}`}
                    </Text>
                  ))}
                </Box>
              </Stack>
            }
            bg="white"
            color="black"
          >
            <Image
              src="/icons/mui-plus.svg"
              alt=""
              onMouseEnter={() => setOpenTooltips(true)}
              onMouseLeave={() => setOpenTooltips(false)}
            />
          </Tooltip>
        )}
        {(currentValue === 0 || name === 'Gula') && (
          <Tooltip
            borderRadius={6}
            hasArrow
            placement="bottom-end"
            isOpen={name !== 'Gula' && openTooltips}
            label={
              <Box>
                <Text fontSize={14}>
                  Kamu belum mencatat makanan di hari ini. Silakan catat makanan terlebih dahulu.
                </Text>
              </Box>
            }
            bg="white"
            color="black"
          >
            <Image
              src={name === 'Gula' ? '/icons/goal-check-small.svg' : '/icons/mui-plus.svg'}
              alt=""
              onMouseEnter={() => setOpenTooltips(true)}
              onMouseLeave={() => setOpenTooltips(false)}
            />
          </Tooltip>
        )}
        {name !== 'Gula' && currentValue >= min && currentValue <= max && (
          <Image src="/icons/goal-check-small.svg" alt="" />
        )}
      </HStack>
    </Box>
  )
}

export default GoalSlider
