const gglRound = (number) => {
  if (number instanceof Array) {
    return number
  }
  number = parseFloat(number)
  number = number.toFixed(3)
  const decimal = number - Math.floor(number)

  return decimal >= 0.51 ? Math.ceil(number) : Math.floor(number)
}

export default gglRound
